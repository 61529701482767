export const FETCH_DATA = 'PQT_DEPARTMENT/FETCH_DATA';
export const FETCH_DATA_SUCCESS = 'PQT_DEPARTMENT/FETCH_DATA_SUCCESS';
export const FETCH_DATA_ERROR = 'PQT_DEPARTMENT/FETCH_DATA_ERROR';
export const FETCH_INFORMATION = 'PQT_DEPARTMENT/FETCH_INFORMATION';
export const FETCH_INFORMATION_SUCCESS = 'PQT_DEPARTMENT/FETCH_INFORMATION_SUCCESS';
export const FETCH_INFORMATION_ERROR = 'PQT_DEPARTMENT/FETCH_INFORMATION_ERROR';
export const FETCH_JOBS = 'PQT_DEPARTMENT/FETCH_JOBS';
export const FETCH_JOBS_SUCCESS = 'PQT_DEPARTMENT/FETCH_JOBS_SUCCESS';
export const FETCH_JOBS_ERROR = 'PQT_DEPARTMENT/FETCH_JOBS_ERROR';
export const FETCH_OPERATORS = 'PQT_DEPARTMENT/FETCH_OPERATORS';
export const FETCH_OPERATORS_SUCCESS = 'PQT_DEPARTMENT/FETCH_OPERATORS_SUCCESS';
export const FETCH_OPERATORS_ERROR = 'PQT_DEPARTMENT/FETCH_OPERATORS_ERROR';
export const DELETE_TRACKING = 'PQT_DEPARTMENT/DELETE_TRACKING';
export const DELETE_TRACKING_SUCCESS = 'PQT_DEPARTMENT/DELETE_TRACKING_SUCCESS';
export const DELETE_TRACKING_ERROR = 'PQT_DEPARTMENT/DELETE_TRACKING_ERROR';
export const FETCH_DEPARTMENT = 'PQT_DEPARTMENT/FETCH_DEPARTMENT';
export const FETCH_DEPARTMENT_SUCCESS = 'PQT_DEPARTMENT/FETCH_DEPARTMENT_SUCCESS';
export const FETCH_DEPARTMENT_ERROR = 'PQT_DEPARTMENT/FETCH_DEPARTMENT_ERROR';
export const CLOSE_OUT_JOB = 'PQT_DEPARTMENT/CLOSE_OUT_JOB';
export const CLOSE_OUT_JOB_SUCCESS = 'PQT_DEPARTMENT/CLOSE_OUT_JOB_SUCCESS';
export const CLOSE_OUT_JOB_ERROR = 'PQT_DEPARTMENT/CLOSE_OUT_JOB_ERROR';
export const SAVE_PARTIAL = 'PQT_DEPARTMENT/SAVE_PARTIAL';
export const SAVE_PARTIAL_SUCCESS = 'PQT_DEPARTMENT/SAVE_PARTIAL_SUCCESS';
export const SAVE_PARTIAL_ERROR = 'PQT_DEPARTMENT/SAVE_PARTIAL_ERROR';
export const SAVE_SHIPPING = 'PQT_DEPARTMENT/SAVE_SHIPPING';
export const SAVE_SHIPPING_SUCCESS = 'PQT_DEPARTMENT/SAVE_SHIPPING_SUCCESS';
export const SAVE_SHIPPING_ERROR = 'PQT_DEPARTMENT/SAVE_SHIPPING_ERROR';
export const SAVE_MAILING = 'PQT_DEPARTMENT/SAVE_MAILING';
export const SAVE_MAILING_SUCCESS = 'PQT_DEPARTMENT/SAVE_MAILING_SUCCESS';
export const SAVE_MAILING_ERROR = 'PQT_DEPARTMENT/SAVE_MAILING_ERROR';
export const UPLOAD_MAILDAT_FILE = 'PQT_DEPARTMENT/UPLOAD_MAILDAT_FILE';
export const UPLOAD_MAILDAT_FILE_SUCCESS = 'PQT_DEPARTMENT/UPLOAD_MAILDAT_FILE_SUCCESS';
export const UPLOAD_MAILDAT_FILE_ERROR = 'PQT_DEPARTMENT/UPLOAD_MAILDAT_FILE_ERROR';
export const RESET_STATE = 'PQT_DEPARTMENT/RESET_STATE';
export const SET_SEARCH_FIELD = 'PQT_DEPARTMENT/SET_SEARCH_FIELD';
export const SET_ID = 'PQT_DEPARTMENT/SET_ID';
export const SET_CLOSE_OUT_FIELD = 'PQT_DEPARTMENT/SET_CLOSE_OUT_FIELD';
export const SELECT_JOB = 'PQT_DEPARTMENT/SELECT_JOB';
export const SET_BARCODE = 'PQT_DEPARTMENT/SET_BARCODE';
export const SET_BARCODE_CAPTURING = 'PQT_DEPARTMENT/SET_BARCODE_CAPTURING';
export const SET_SORT = 'PQT_DEPARTMENT/SET_SORT';
export const SET_ACTIVE_PAGE = 'PQT_DEPARTMENT/SET_ACTIVE_PAGE';
export const ADD_TASK = 'PQT_DEPARTMENT/ADD_TASK';
export const REMOVE_TASK = 'PQT_DEPARTMENT/REMOVE_TASK';
export const SET_TASK_FIELD = 'PQT_DEPARTMENT/SET_TASK_FIELD';
export const SET_SHOW_JOBS = 'PQT_DEPARTMENT/SET_SHOW_JOBS';
export const SET_PARTIAL_FIELD = 'PQT_DEPARTMENT/SET_PARTIAL_FIELD';
export const ADD_PARTIAL = 'PQT_DEPARTMENT/ADD_PARTIAL';
export const REMOVE_PARTIAL = 'PQT_DEPARTMENT/REMOVE_PARTIAL';
export const SET_SHIPPING_FIELD = 'PQT_DEPARTMENT/SET_SHIPPING_FIELD';
export const ADD_SHIPPING = 'PQT_DEPARTMENT/ADD_SHIPPING';
export const REMOVE_SHIPPING = 'PQT_DEPARTMENT/REMOVE_SHIPPING';
export const ADD_CREW_PERSON = 'PQT_DEPARTMENT/ADD_CREW_PERSON';
export const DELETE_CREW_PERSON = 'PQT_DEPARTMENT/DELETE_CREW_PERSON';
export const SET_CREW_PERSON_FIELD = 'PQT_DEPARTMENT/SET_CREW_PERSON_FIELD';
export const ADD_JOB_DETAIL_LINE = 'PQT_DEPARTMENT/ADD_JOB_DETAIL_LINE';
export const DELETE_JOB_DETAIL_LINE = 'PQT_DEPARTMENT/DELETE_JOB_DETAIL_LINE';
export const SET_JOB_DETAIL_LINE_FIELD = 'PQT_DEPARTMENT/SET_JOB_DETAIL_LINE_FIELD';
export const ADD_MAILDAT_FILE = 'PQT_DEPARTMENT/ADD_MAILDAT_FILE';