import { createSelector } from 'reselect';

export const getComponentState = state => state.quickPostageEscrow;

export const getCustomers = createSelector(
    getComponentState,
    (state) => state.customers
);
export const getCompanyId = createSelector(
    getComponentState,
    (state) => state.companyId
);
export const getCompany = createSelector(
    getComponentState,
    (state) => state.company
);
export const getReport = createSelector(
    getComponentState,
    (state) => state.report
);
export const getIsFechingCustomers = createSelector(
    getComponentState,
    (state) => state.isFechingCustomers
);
export const getIsFechingReport = createSelector(
    getComponentState,
    (state) => state.isFechingReport
);
export const getActiveTab = createSelector(
    getComponentState,
    (state) => state.activeTab
);
export const getSalesId = createSelector(
    getComponentState,
    (state) => state.salesId
);
export const getSaleReps = createSelector(
    getComponentState,
    (state) => state.saleReps
);
export const getInvoicesSalesReps = createSelector(
    getComponentState,
    (state) => state.invoicesSalesReps
);
export const getFromDate = createSelector(
    getComponentState,
    (state) => state.fromDate
);
export const getToDate = createSelector(
    getComponentState,
    (state) => state.toDate
);
export const getPaidDate = createSelector(
    getComponentState,
    (state) => state.paidDate
);
export const getExportingSalesReport = createSelector(
    getComponentState,
    (state) => state.exportingSalesReport
);
export const getExportingCommissionReport = createSelector(
    getComponentState,
    (state) => state.exportingCommissionReport
);
export const getExportingARAgingReport = createSelector(
    getComponentState,
    (state) => state.exportingARAgingReport
);
export const getUploadingFile = createSelector(
    getComponentState,
    (state) => state.uploadingFile
);
export const getCustomerId = createSelector(
    getComponentState,
    (state) => state.customerId
);
export const getSalesCustomers = createSelector(
    getComponentState,
    (state) => state.salesCustomers
);
export const getIsFechingSalesCustomers = createSelector(
    getComponentState,
    (state) => state.isFechingSalesCustomers
);
export const getDepartments = createSelector(
    getComponentState,
    (state) => state.departments
);
export const getSelectedDepartments = createSelector(
    getComponentState,
    (state) => state.selectedDepartments
);
export const getExportingInvoicesReport = createSelector(
    getComponentState,
    (state) => state.exportingInvoicesReport
);
export const getInvoiceReportType = createSelector(
    getComponentState,
    (state) => state.invoiceReportType
);
export const getExportingCombinedReport = createSelector(
    getComponentState,
    (state) => state.exportingCombinedReport
);
export const getSendingSalesReport = createSelector(
    getComponentState,
    (state) => state.sendingSalesReport
);
export const getSendingSalesNormReport = createSelector(
    getComponentState,
    (state) => state.sendingSalesNormReport
);
export const getSalesReportInfoChanged = createSelector(
    getComponentState,
    (state) => state.salesReportInfoChanged
);
export const getShippingMethod = createSelector(
    getComponentState,
    (state) => state.shippingMethod
);
export const getExportingShippingReport = createSelector(
    getComponentState,
    (state) => state.exportingShippingReport
);
export const getUploadingShippingReport = createSelector(
    getComponentState,
    (state) => state.uploadingShippingReport
);
export const getExportingMailingReport = createSelector(
    getComponentState,
    (state) => state.exportingMailingReport
);
export const getExportingYearlySalesReport = createSelector(
    getComponentState,
    (state) => state.exportingYearlySalesReport
);
export const getExportingJobStatusReport = createSelector(
    getComponentState,
    (state) => state.exportingJobStatusReport
);
export const getJobsType = createSelector(
    getComponentState,
    (state) => state.jobsType
);