import { createSelector } from 'reselect';

export const getComponentState = state => state.pqtDepartment;

export const getId = createSelector(
    getComponentState,
    (state) => state.id
);

/* information */
export const getInformation = createSelector(
    getComponentState,
    (state) => state.information
);
export const getSalesRepsInformation = createSelector(
    getComponentState,
    (state) => state.information.salesReps
);
export const getCsrsInformation = createSelector(
    getComponentState,
    (state) => state.information.csrs
);
export const getDepartmentsInformation = createSelector(
    getComponentState,
    (state) => state.information.departments
);

/* fetching */
export const getFetching = createSelector(
    getComponentState,
    (state) => state.fetching
);
export const getFetchingInformation = createSelector(
    getComponentState,
    (state) => state.fetching.information
);
export const getFetchingJobs = createSelector(
    getComponentState,
    (state) => state.fetching.jobs
);
export const getFetchingDepartment = createSelector(
    getComponentState,
    (state) => state.fetching.department
);

/* data */
export const getData = createSelector(
    getComponentState,
    (state) => state.data
);
export const getJobsData = createSelector(
    getComponentState,
    (state) => state.data.jobs
);
export const getOperatorsData = createSelector(
    getComponentState,
    (state) => state.data.operators
);

/* pagination */
export const getPagination = createSelector(
    getComponentState,
    (state) => state.pagination
);
export const getJobsPagination = createSelector(
    getComponentState,
    (state) => state.pagination.jobs
);

export const getSearchFields = createSelector(
    getComponentState,
    (state) => state.searchFields
);

export const getDepartment = createSelector(
    getComponentState,
    (state) => state.department
);

export const getCloseOutFields = createSelector(
    getComponentState,
    (state) => state.closeOutFields
);

export const getBarcode = createSelector(
    getComponentState,
    (state) => state.barcode
);

export const getSort = createSelector(
    getComponentState,
    (state) => state.sort
);

export const getShowJobs = createSelector(
    getComponentState,
    (state) => state.showJobs
);
export const getMaildatFile = createSelector(
    getComponentState,
    (state) => state.maildatFile
);
export const getUploadingMaildatFile = createSelector(
    getComponentState,
    (state) => state.uploadingMaildatFile
);