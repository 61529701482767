import React, { Fragment } from 'react';
import moment from 'moment';
import numeral from 'numeral';
import { showNotification, empty } from './../../../utils';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import { formatDate, parseDate } from 'react-day-picker/moment';
import { useDropzone } from 'react-dropzone';

const CloseOut = React.memo((props) => {
    const {
        // acceptedFiles,
        // fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        multiple: false,
        onDrop: (files) => {
            props.addMaildatFile(files[0]);
        }
    });

    const validatePartialJobQuantity = () => {
        if (props.department != null && parseInt(props.department.id) !== 56) {
            return true;
        }

        let totalQuantity = 0;
        for (let i = 0; i < props.closeOutFields.tasks[0].partials.length; i++) {
            totalQuantity += parseInt(props.closeOutFields.tasks[0].partials[i].final_quantity);
        }

        for (let i = 0; i < props.closeOutFields.job.partials.length; i++) {
            totalQuantity += parseInt(props.closeOutFields.job.partials[i].quantity);
        }

        if (totalQuantity > props.closeOutFields.job.quantity) {
            showNotification('Quantity limit', 'The total quantity entered exceed the quantity of the job', 'info');
            return false;
        }

        return true;
    };

    const handleSavePartial = () => {
        if (validatePartialJobQuantity()) {
            props.savePartial();
        }
    };

    const handleSaveShipping = () => {
        props.saveShipping();
    };

    const handleSaveMailing = () => {
        props.saveMailing();
    };

    const handleCloseJob = () => {
        if (validatePartialJobQuantity()) {
            props.closeOutJob();
        }
    };

    let closeButton = null;
    let savePartialButton = null;
    let saveShippingButton = null;
    let saveMailingButton = null;
    if (props.closeOutFields.id && props.closeOutFields.id != null) {
        closeButton = <button onClick={handleCloseJob}><i className="fas fa-clipboard-check"></i> Closeout</button>;
        if (props.closeOutFields.closing) {
            closeButton = <button disabled><i className="fas fa-circle-notch fa-spin" /></button>;
        }

        if (parseInt(props.department.id) === 56) {     // Postal Accounting
            savePartialButton = <button onClick={handleSavePartial} className="mr-3"><i className="fas fa-save"></i> Save Partial</button>;
            if (props.closeOutFields.savingPartial) {
                savePartialButton = <button disabled className="mr-3"><i className="fas fa-circle-notch fa-spin" /></button>;
            }
        } else if (parseInt(props.department.id) === 17) {     // Shipping
            saveShippingButton = <button onClick={handleSaveShipping} className="mr-3"><i className="fas fa-save"></i> Save Shipping</button>;
            if (props.closeOutFields.savingShipping) {
                saveShippingButton = <button disabled className="mr-3"><i className="fas fa-circle-notch fa-spin" /></button>;
            }
        } else if ([12, 26, 36].includes(parseInt(props.department.id))) {     // Inserting (12), Hand (26), Mail Services (36)
            saveMailingButton = <button onClick={handleSaveMailing} className="mr-3"><i className="fas fa-save"></i> Save</button>;
            if (props.closeOutFields.savingMailing) {
                saveMailingButton = <button disabled className="mr-3"><i className="fas fa-circle-notch fa-spin" /></button>;
            }
        }
    }

    const setCloseOutFieldHandler = (name, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }
        props.setCloseOutField(name, value);
    };

    const setTaskFieldHandler = (id, name, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }
        props.setTaskField(id, name, value);
    };

    const setPartialFieldHandler = (index, name, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }
        props.setPartialField(index, name, value);
    };

    const setShippingFieldHandler = (index, name, event) => {
        let value = event.target.value;
        if (event.target.type === 'checkbox') {
            value = event.target.checked ? 1 : 0;
        }
        props.setShippingField(index, name, value);
    };

    const checkTaskHandler = (id, event) => {
        if (event.target.checked) {
            props.addTask(id);
        } else {
            props.removeTask(id);
        }
    };

    const renderCloseOutTasks = (allowedStep) => (
        allowedStep.subdepartments.map((subdepartment, index) => {
            let task = null;
            for (let j = 0; j < props.closeOutFields.tasks.length; j++) {
                if (parseInt(props.closeOutFields.tasks[j].id) === parseInt(subdepartment.id)) {
                    task = props.closeOutFields.tasks[j];
                    break;
                }
            }

            let equipmentValue = '';
            if (task != null) {
                equipmentValue = task.equipment;
            }
            let finalQuantityValue = '';
            if (task != null) {
                finalQuantityValue = task.final_quantity;
            }

            if (subdepartment.finished) {
                return null;
            }

            return (
                <div className="subdepartment-container" key={'subdepartment-' + index}>
                    <div className="checkbox-field">
                        <input type="checkbox" onClick={checkTaskHandler.bind(this, subdepartment.id)} />
                        {subdepartment.name}
                    </div>
                    <div className="field">
                        <b>Equipment <span className="required">*</span>:</b>
                        <input
                            type="text"
                            placeholder="Enter Equipment"
                            value={equipmentValue}
                            disabled={task == null}
                            onChange={setTaskFieldHandler.bind(this, subdepartment.id, 'equipment')}
                        />
                    </div>
                    <div className="field">
                        <b>Final Quantity <span className="required">*</span>:</b>
                        <input
                            type="number"
                            placeholder="Enter Final Quantity"
                            value={finalQuantityValue}
                            disabled={task == null}
                            onChange={setTaskFieldHandler.bind(this, subdepartment.id, 'final_quantity')}
                        />
                    </div>
                </div>
            );
        })
    );

    const renderPartialsFields = () => {
        if (props.closeOutFields.tasks.length === 0) {
            return null;
        }

        return props.closeOutFields.tasks[0].partials.map((partial, index) => {
            let finalQuantityValue = partial.final_quantity;
            let projectMailValue = partial.project_mail;
            let mailTypeValue = partial.mail_type;
            let permitNumberValue = partial.permit_number;
            let postOfficeValue = partial.post_office;

            let projectMail = parseInt(partial.project_mail);
            let isPermitRequired = true;
            if (projectMail === 3 || projectMail === 4) {
                isPermitRequired = false;
            }

            let number = index + 1;
            return (
                <Fragment>
                    <div className="field">
                        <b>Project Mail {number} <span className="required">*</span>:</b>
                        <select value={projectMailValue} onChange={setPartialFieldHandler.bind(this, index, 'project_mail')}>
                            <option value="">Select method</option>
                            <option value="1">Customer Permit</option>
                            <option value="2">ACM permit #1</option>
                            <option value="3">ACM Meter</option>
                            <option value="4">ACM Stamp</option>
                        </select>
                    </div>
                    <div className="field">
                        <b>Mail Type {number} <span className="required">*</span>:</b>
                        <select value={mailTypeValue} onChange={setPartialFieldHandler.bind(this, index, 'mail_type')}>
                            <option value="">Select method</option>
                            <option value="1">FCP: FIRST CLASS PRESORT</option>,
                            <option value="2">FC: FIRST CLASS</option>
                            <option value="3">STD: STANDARD</option>
                            <option value="4">NP: NON-PROFIT</option>
                            <option value="5">PER: PERIODICAL</option>
                            <option value="6">BPM: BOUND PRINTED MAT</option>
                            <option value="7">PIR: PRIORITY MAIL</option>
                            <option value="8">PS: PARCEL SELECT</option>
                            <option value="9">INT: INTERNATIONAL MAIL</option>
                        </select>
                    </div>
                    <div className="field">
                        <b>Permit Number {number} {isPermitRequired && <span className="required">*</span>}:</b>
                        <input
                            type="text"
                            placeholder="Enter Permit Number"
                            value={permitNumberValue}
                            onChange={setPartialFieldHandler.bind(this, index, 'permit_number')}
                        />
                    </div>
                    <div className="field">
                        <b>Post Office {number} <span className="required">*</span>:</b>
                        <input
                            type="text"
                            placeholder="Enter Post Office"
                            value={postOfficeValue}
                            onChange={setPartialFieldHandler.bind(this, index, 'post_office')}
                        />
                    </div>
                    <div className="field">
                        <b>Final Quantity {number} <span className="required">*</span>:</b>
                        <input
                            type="number"
                            placeholder="Enter Final Quantity"
                            value={finalQuantityValue}
                            onChange={setPartialFieldHandler.bind(this, index, 'final_quantity')}
                        />
                    </div>
                    <div className="field">
                        <b>Cost {number} <span className="required">*</span>:</b>
                        <input
                            type="number"
                            placeholder="Enter Cost"
                            onChange={setPartialFieldHandler.bind(this, index, 'price')}
                        />
                    </div>
                    <div className="divider"></div>
                </Fragment>
            );
        });
    };

    const renderShippingFields = () => {
        if (props.closeOutFields.tasks.length === 0) {
            return null;
        }

        return props.closeOutFields.tasks[0].shippings.map((shipping, index) => {
            let quantityValue = shipping.quantity;
            let shippingMethodValue = shipping.shipping_method;
            let trackingNumberValue = shipping.tracking_number;
            let shippingCostValue = shipping.shipping_cost;

            let number = index + 1;
            return (
                <Fragment>
                    <div className="field">
                        <b>Final Quantity {number} <span className="required">*</span>:</b>
                        <input
                            type="number"
                            placeholder="Enter Final Quantity"
                            value={quantityValue}
                            onChange={setShippingFieldHandler.bind(this, index, 'quantity')}
                        />
                    </div>
                    <div className="field">
                        <b>Shipping Method {number} <span className="required">*</span>:</b>
                        <select value={shippingMethodValue} onChange={setShippingFieldHandler.bind(this, index, 'shipping_method')}>
                            <option value="">Select method</option>
                            <option value="FedEx">FedEx</option>
                            <option value="UPS">UPS</option>
                            <option value="FedEx Freight">FedEx Freight</option>
                            <option value="FedEx Overnight">FedEx Overnight</option>
                            <option value="Customer Account">Customer Account</option>
                            <option value="Delivery Service">Delivery Service</option>
                            <option value="Other Freight">Other Freight</option>
                            <option value="ACM truck">ACM truck</option>
                            <option value="Will Call">Will Call</option>
                        </select>
                    </div>
                    <div className="field">
                        <b>Tracking Number {number} <span className="required">*</span>:</b>
                        <input
                            type="number"
                            placeholder="Enter Tracking Number"
                            value={trackingNumberValue}
                            onChange={setShippingFieldHandler.bind(this, index, 'tracking_number')}
                        />
                    </div>
                    <div className="field">
                        <b>Shipping Cost {number} <span className="required">*</span>:</b>
                        <input
                            type="number"
                            placeholder="Enter Price"
                            value={shippingCostValue}
                            onChange={setShippingFieldHandler.bind(this, index, 'shipping_cost')}
                        />
                    </div>
                    <div className="divider"></div>
                </Fragment>
            );
        });
    };

    const renderSubdepartments = () => {
        let fieldsDisabled = false;
        if (!props.closeOutFields.job) {
            fieldsDisabled = true;
        }

        if (props.department != null && props.department.subdepartments.length > 0) {
            if (props.closeOutFields.job) {
                for (let i = 0; i < props.closeOutFields.job.allowed_steps.length; i++) {
                    const allowedStep = props.closeOutFields.job.allowed_steps[i];
                    if (parseInt(allowedStep.department_id) === parseInt(props.department.id) && parseInt(allowedStep.status) === 2) {
                        return (
                            <Fragment>
                                <div className="select-tasks">Select Tasks to Closeout</div>
                                {renderCloseOutTasks(allowedStep)}
                            </Fragment>
                        );
                    }
                }
            }

            return null;
        } else {
            let equipmentValue = '';
            if (props.closeOutFields.tasks.length > 0) {
                equipmentValue = props.closeOutFields.tasks[0].equipment;
            }
            let finalQuantityValue = '';
            if (props.closeOutFields.tasks.length > 0) {
                finalQuantityValue = props.closeOutFields.tasks[0].final_quantity;
            }

            let shippingMethodField = null;
            let trackingNumberField = null;
            let priceField = <div className="field"></div>;
            let markupField = null;
            let mailTypeField = null;
            let postageMailField = null;
            let permitNumberField = null;
            let postOfficeField = null;
            let price2Field = null;
            let mailType2Field = null;
            let postageMail2Field = null;
            let permitNumber2Field = null;
            let postOffice2Field = null;
            let divider = null;
            let partialsFields = null;
            let shippingFields = null;
            let partialsButtons = null;
            let shippingButtons = null;
            let proofsField = null;
            let proofTypeField = null;
            let daysField = null;
            let hoursField = null;
            let mailDatField = null;
            let minutesField = null;
            let equipmentField = (
                <div className="field">
                    <b>Equipment <span className="required">*</span>:</b>
                    <input
                        type="text"
                        placeholder="Enter Equipment"
                        value={equipmentValue}
                        disabled={fieldsDisabled}
                        onChange={setTaskFieldHandler.bind(this, 0, 'equipment')}
                    />
                </div>
            );
            let quantityField = (
                <div className="field">
                    <b>Final Quantity <span className="required">*</span>:</b>
                    <input
                        type="number"
                        placeholder="Enter Final Quantity"
                        value={finalQuantityValue}
                        disabled={fieldsDisabled}
                        onChange={setTaskFieldHandler.bind(this, 0, 'final_quantity')}
                    />
                </div>
            );
            let quantity2Field = null;
            if (parseInt(props.department.id) === 56) {     // Postal Accounting
                quantityField = null;
                priceField = null;
                equipmentField = null;
                partialsFields = renderPartialsFields();

                if (props.closeOutFields.tasks.length > 0) {
                    partialsButtons = (
                        <div className="partial-buttons">
                            <div className="add-button" onClick={props.addPartial}><i className="fas fa-plus" /> Add Partial</div>
                            <div className="remove-button" onClick={props.removePartial}><i className="fas fa-minus-circle" /> Remove Partial</div>
                        </div>
                    );
                }

                let buttonContainer = (
                    <div {...getRootProps({ className: 'dropzone-container' })}>
                        <input {...getInputProps()} />
                        <button className="browse-files-button">Browse File</button>
                    </div>
                );
                if (fieldsDisabled) {
                    buttonContainer = (
                        <div>
                            <button className="browse-files-button-disabled" disabled>Browse File</button>
                        </div>
                    );
                }

                let selectedFile = null;
                if (props.maildatFile != null) {
                    selectedFile = <div>{props.maildatFile.name}</div>
                }

                mailDatField = (
                    <div className="field">
                        <b>Mail.dat File (For Closeout):</b>
                        {buttonContainer}
                        {selectedFile}
                    </div>
                );
            } else if (parseInt(props.department.id) === 17) {     // Shipping
                quantityField = null;
                priceField = null;
                equipmentField = null;
                shippingFields = renderShippingFields();

                if (props.closeOutFields.tasks.length > 0) {
                    shippingButtons = (
                        <div className="shipping-buttons">
                            <div className="add-button" onClick={props.addShipping}><i className="fas fa-plus" /> Add Shipping</div>
                            <div className="remove-button" onClick={props.removeShipping}><i className="fas fa-minus-circle" /> Remove Shipping</div>
                        </div>
                    );
                }
            } else if (parseInt(props.department.id) === 55) {     // Postal Qualification
                priceField = (
                    <div className="field">
                        <b>Postage Amount <span className="required">*</span>:</b>
                        <input
                            type="number"
                            placeholder="Enter Postage"
                            disabled={fieldsDisabled}
                            onChange={setTaskFieldHandler.bind(this, 0, 'postage')}
                        />
                    </div>
                );
                equipmentField = (
                    <div className="field">
                        <b>Postage Method <span className="required">*</span>:</b>
                        <select value={equipmentValue} onChange={setTaskFieldHandler.bind(this, 0, 'equipment')} disabled={fieldsDisabled}>
                            <option value="">Select method</option>
                            <option value="1">Customer Permit</option>
                            <option value="2">ACM permit #1</option>
                            <option value="3">ACM Meter</option>
                            <option value="4">ACM Stamp</option>
                        </select>
                    </div>
                );

                let buttonContainer = (
                    <div {...getRootProps({ className: 'dropzone-container' })}>
                        <input {...getInputProps()} />
                        <button className="browse-files-button">Browse File</button>
                    </div>
                );
                if (fieldsDisabled) {
                    buttonContainer = (
                        <div>
                            <button className="browse-files-button-disabled" disabled>Browse File</button>
                        </div>
                    );
                }

                let selectedFile = null;
                if (props.maildatFile != null) {
                    selectedFile = <div>{props.maildatFile.name}</div>
                }

                mailDatField = (
                    <div className="field">
                        <b>Mail.dat File:</b>
                        {buttonContainer}
                        {selectedFile}
                    </div>
                );
            } else if (parseInt(props.department.id) === 23) {     // Art & Graphic
                quantityField = null;
                priceField = null;
                proofsField = (
                    <div className="field art-proofs">
                        <b>Number of Proofs <span className="required">*</span>:</b>
                        <input
                            type="number"
                            placeholder="Number of Proofs"
                            disabled={fieldsDisabled}
                            onChange={setTaskFieldHandler.bind(this, 0, 'art_proofs')}
                        />
                    </div>
                );
                proofTypeField = (
                    <div className="field art-proof-type">
                        <b>Proof Type <span className="required">*</span>:</b>
                        <select onChange={setTaskFieldHandler.bind(this, 0, 'art_proof_type')} disabled={fieldsDisabled}>
                            <option value="">Select</option>
                            <option value="1">PDF</option>
                            <option value="2">Hardcopy</option>
                            <option value="3">Both</option>
                        </select>
                    </div>
                );
                daysField = (
                    <Fragment>
                        <div className="field art-field">
                            <b>Days <span className="required">*</span>:</b>
                            <select onChange={setTaskFieldHandler.bind(this, 0, 'art_days')} disabled={fieldsDisabled}>
                                <option value="">Select</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                            </select>
                        </div>
                        <div className="colon-container">:</div>
                    </Fragment>
                );
                hoursField = (
                    <Fragment>
                        <div className="field art-field">
                            <b>Hours <span className="required">*</span>:</b>
                            <select onChange={setTaskFieldHandler.bind(this, 0, 'art_hours')} disabled={fieldsDisabled}>
                                <option value="">Select</option>
                                <option value="0">0</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                            </select>
                        </div>
                        <div className="colon-container">:</div>
                    </Fragment>
                );
                minutesField = (
                    <div className="field art-field">
                        <b>Minutes <span className="required">*</span>:</b>
                        <select onChange={setTaskFieldHandler.bind(this, 0, 'art_minutes')} disabled={fieldsDisabled}>
                            <option value="">Select</option>
                            <option value="0">0</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="40">40</option>
                            <option value="50">50</option>
                        </select>
                    </div>
                );
            }

            return (
                <Fragment>
                    {postageMailField}
                    {mailTypeField}
                    {permitNumberField}
                    {postOfficeField}
                    {equipmentField}
                    {quantityField}
                    {shippingMethodField}
                    {trackingNumberField}
                    {priceField}
                    {markupField}
                    {partialsFields}
                    {partialsButtons}
                    {shippingFields}
                    {shippingButtons}
                    {divider}
                    {postageMail2Field}
                    {mailType2Field}
                    {permitNumber2Field}
                    {postOffice2Field}
                    {quantity2Field}
                    {price2Field}
                    {proofsField}
                    {proofTypeField}
                    {daysField}
                    {hoursField}
                    {minutesField}
                    {mailDatField}
                    {divider}
                </Fragment>
            );
        }
    };

    const renderPartials = () => {
        if (props.department != null && parseInt(props.department.id) === 56 && props.closeOutFields.job && props.closeOutFields.job.partials.length > 0) {
            let totalQuantity = 0;
            let totalCost = 0;
            for (let i = 0; i < props.closeOutFields.job.partials.length; i++) {
                const partial = props.closeOutFields.job.partials[i];
                totalQuantity += parseInt(partial.quantity);
                totalCost += parseFloat(partial.cost);
            }

            return (
                <Fragment>
                    <div className="partial-title">Mailed:</div>
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th>Project Mail</th>
                                <th>Mail Type</th>
                                <th>Permit Number</th>
                                <th>Post Office</th>
                                <th className="text-right">Quantity</th>
                                <th className="text-right">Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.closeOutFields.job.partials.map((partial) => {
                                let projectMailName = '';
                                if (parseInt(partial.project_mail) === 1) {
                                    projectMailName = 'Customer Permit';
                                } else if (parseInt(partial.project_mail) === 2) {
                                    projectMailName = 'ACM Permit #1';
                                } else if (parseInt(partial.project_mail) === 3) {
                                    projectMailName = 'ACM Meter';
                                } else if (parseInt(partial.project_mail) === 4) {
                                    projectMailName = 'ACM Stamp';
                                } else if (parseInt(partial.project_mail) === 5) {
                                    projectMailName = 'ACM Paid Postage';
                                } else if (parseInt(partial.project_mail) === 6) {
                                    projectMailName = 'Prepaid Postage';
                                }

                                let mailTypeName = '';
                                if (parseInt(partial.mail_type) === 1) {
                                    mailTypeName = 'FCP: FIRST CLASS PRESORT';
                                } else if (parseInt(partial.mail_type) === 2) {
                                    mailTypeName = 'FC: FIRST CLASS';
                                } else if (parseInt(partial.mail_type) === 3) {
                                    mailTypeName = 'STD: STANDARD';
                                } else if (parseInt(partial.mail_type) === 4) {
                                    mailTypeName = 'NP: NON-PROFIT';
                                } else if (parseInt(partial.mail_type) === 5) {
                                    mailTypeName = 'PER: PERIODICAL';
                                } else if (parseInt(partial.mail_type) === 6) {
                                    mailTypeName = 'BPM: BOUND PRINTED MAT';
                                } else if (parseInt(partial.mail_type) === 7) {
                                    mailTypeName = 'PIR: PRIORITY MAIL';
                                } else if (parseInt(partial.mail_type) === 8) {
                                    mailTypeName = 'PS: PARCEL SELECT';
                                } else if (parseInt(partial.mail_type) === 9) {
                                    mailTypeName = 'INT: INTERNATIONAL MAIL';
                                }

                                return (
                                    <tr>
                                        <td>{projectMailName}</td>
                                        <td>{mailTypeName}</td>
                                        <td>{partial.permit_number}</td>
                                        <td>{partial.post_office}</td>
                                        <td className="text-right">{numeral(partial.quantity).format('0,0')}</td>
                                        <td className="text-right">{numeral(partial.cost).format('$0,0.00')}</td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td className="total-td text-right" colSpan="4"><b>Total:</b></td>
                                <td className="text-right"><b>{numeral(totalQuantity).format('0,0')}</b></td>
                                <td className="text-right"><b>{numeral(totalCost).format('$0,0.00')}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </Fragment>
            );
        }

        return null;
    };

    const renderShippings = () => {
        if (props.department != null && parseInt(props.department.id) === 17 && props.closeOutFields.job && props.closeOutFields.job.shippings.length > 0) {
            let totalQuantity = 0;
            let totalCost = 0;
            for (let i = 0; i < props.closeOutFields.job.shippings.length; i++) {
                const shipping = props.closeOutFields.job.shippings[i];
                totalQuantity += parseInt(shipping.quantity);
                totalCost += parseFloat(shipping.shipping_cost);
            }

            return (
                <Fragment>
                    <div className="shipping-title">Mailed:</div>
                    <table className="table table-sm">
                        <thead>
                            <tr>
                                <th>Shipping Method</th>
                                <th>Tracking Number</th>
                                <th className="text-right">Quantity</th>
                                <th className="text-right">Shipping Cost</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.closeOutFields.job.shippings.map((shipping) => {
                                return (
                                    <tr>
                                        <td>{shipping.shipping_method}</td>
                                        <td>{shipping.tracking_number}</td>
                                        <td className="text-right">{numeral(shipping.quantity).format('0,0')}</td>
                                        <td className="text-right">{numeral(shipping.shipping_cost).format('$0,0.00')}</td>
                                    </tr>
                                );
                            })}
                            <tr>
                                <td className="total-td text-right" colSpan="2"><b>Total:</b></td>
                                <td className="text-right"><b>{numeral(totalQuantity).format('0,0')}</b></td>
                                <td className="text-right"><b>{numeral(totalCost).format('$0,0.00')}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </Fragment>
            );
        }

        return null;
    };

    let buttonClassName = '';
    if (props.department != null && props.department.subdepartments.length > 0) {
        buttonClassName = ' with-subdepartments';
    }

    let fieldsDisabled = false;
    if (!props.closeOutFields.job) {
        fieldsDisabled = true;
    }

    const renderCrewInformationRecord = (element) => {
        const crewInformation = JSON.parse(element.crew_information);
        if (crewInformation.length === 0) {
            return (
                <tr>
                    <td colSpan="7">No data</td>
                </tr>
            );
        }

        return crewInformation.map((item) => {
            return (
                <tr>
                    <td>{item.operator_name}</td>
                    <td>{item.roll}</td>
                    <td>{moment(item.dateIn + ' ' + item.timeIn).format('MM/DD/YYYY h:mm a')}</td>
                    <td>{moment(item.dateOut + ' ' + item.timeOut).format('MM/DD/YYYY h:mm a')}</td>
                    <td>{item.startCount}</td>
                    <td>{item.endCount}</td>
                    <td>{item.machine}</td>
                </tr>
            );
        });
    };

    const renderJobDetailsRecord = (element) => {
        const jobDetails = JSON.parse(element.job_details);
        if (jobDetails.length === 0) {
            return (
                <tr>
                    <td colSpan="7">No data</td>
                </tr>
            );
        }

        let allBlank = true;
        for (let i = 0; i < jobDetails.length; i++) {
            const element = jobDetails[i];
            if (!empty(element.skid) || !empty(element.drop) || !empty(element.sacks) || !empty(element.oneTrays) ||
                !empty(element.twoTrays) || !empty(element.twoeTrays) || !empty(element.tubs)) {
                allBlank = false;
                break;
            }
        }

        if (allBlank) {
            return (
                <tr>
                    <td colSpan="7">No data</td>
                </tr>
            );
        }

        return jobDetails.map((item) => {
            return (
                <tr>
                    <td>{item.skid}</td>
                    <td>{item.drop}</td>
                    <td>{item.sacks}</td>
                    <td>{item.oneTrays}</td>
                    <td>{item.twoTrays}</td>
                    <td>{item.twoeTrays}</td>
                    <td>{item.tubs}</td>
                </tr>
            );
        });
    };

    const renderMailingRecord = (data, label = '') => {
        return data.map((element, index) => {
            return (
                <div className="mailing-closeout">
                    <div className="mailing-item-title">
                        {label} Record {index + 1}:
                    </div>
                    <div className="table-row">
                        <div className="table-title">Crew Information:</div>
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th>Operator</th>
                                    <th>Roll</th>
                                    <th>Date In</th>
                                    <th>Date Out</th>
                                    <th>Start Count</th>
                                    <th>End Count</th>
                                    <th>Machine</th>
                                </tr>
                            </thead>
                            <tbody>{renderCrewInformationRecord(element)}</tbody>
                        </table>
                    </div>
                    <div className="table-row">
                        <div className="table-title">Job Details:</div>
                        <table className="table table-sm">
                            <thead>
                                <tr>
                                    <th>Skid #</th>
                                    <th>Drop #</th>
                                    <th>Sacks</th>
                                    <th>1" Trays</th>
                                    <th>2" Trays</th>
                                    <th>2E Trays</th>
                                    <th>Tubs</th>
                                </tr>
                            </thead>
                            <tbody>{renderJobDetailsRecord(element)}</tbody>
                        </table>
                    </div>
                    <div className="notes-row">
                        <div><b>Leftover Material Remarks:</b> {empty(element.leftover_material) ? 'None' : element.leftover_material}</div>
                        <div><b>Job Notes &amp; Remarks:</b> {empty(element.comments) ? 'None' : element.comments}</div>
                    </div>
                </div>
            );
        });
    };

    if (props.department != null && [36, 12, 26].includes(parseInt(props.department.id))) {  // Mail Services(36), Inserting(12), Hand(26)
        const handleSetCrewPersonDateField = (index, name, event) => {
            let value = event === undefined ? '' : moment(event).tz('America/Chicago').format('YYYY-MM-DD');
            props.setCrewPersonField(index, name, value);
        };

        const handleSetCrewPersonField = (index, name, event) => {
            let value = event.target.value;
            if (event.target.type === 'checkbox') {
                value = event.target.checked ? 1 : 0;
            }
            props.setCrewPersonField(index, name, value);
        };

        const renderCrewInformation = () => {
            return props.closeOutFields.crewInformation.map((person, index) => {
                return (
                    <div className="person">
                        <div className="field operator-field">
                            <div className="label">Operator name <span className="required">*</span>:</div>
                            <select disabled={fieldsDisabled} value={person.operator} onChange={handleSetCrewPersonField.bind(this, index, 'operator')}>
                                <option value="">Select</option>
                                {props.operators.map((operator) => {
                                    return <option value={operator.id}>{operator.name}</option>
                                })}
                            </select>
                        </div>
                        <div className="field roll-field">
                            <div className="label">Roll <span className="required">*</span>:</div>
                            <select disabled={fieldsDisabled} value={person.roll} onChange={handleSetCrewPersonField.bind(this, index, 'roll')}>
                                <option value="">Select</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                            </select>
                        </div>
                        <div className="field date-in-field">
                            <div className="label">Date In <span className="required">*</span>:</div>
                            <DayPickerInput
                                formatDate={formatDate}
                                parseDate={parseDate}
                                placeholder=""
                                className="date-picker-field"
                                onDayChange={handleSetCrewPersonDateField.bind(this, index, 'dateIn')}
                                value={person.dateIn == null || person.dateIn === '0000-00-00' ? '' : moment(person.dateIn).format('MM/DD/YYYY')}
                            />
                        </div>
                        <div className="field time-in-field">
                            <div className="label">Time In <span className="required">*</span>:</div>
                            <input type="time" value={person.timeIn} onChange={handleSetCrewPersonField.bind(this, index, 'timeIn')} />
                        </div>
                        <div className="field date-out-field">
                            <div className="label">Date Out <span className="required">*</span>:</div>
                            <DayPickerInput
                                formatDate={formatDate}
                                parseDate={parseDate}
                                placeholder=""
                                className="date-picker-field"
                                onDayChange={handleSetCrewPersonDateField.bind(this, index, 'dateOut')}
                                value={person.dateOut == null || person.dateOut === '0000-00-00' ? '' : moment(person.dateOut).format('MM/DD/YYYY')}
                            />
                        </div>
                        <div className="field time-out-field">
                            <div className="label">Time Out <span className="required">*</span>:</div>
                            <input type="time" value={person.timeOut} onChange={handleSetCrewPersonField.bind(this, index, 'timeOut')} />
                        </div>
                        <div className="field start-count-field">
                            <div className="label">Start count <span className="required">*</span>:</div>
                            <input type="number" disabled={fieldsDisabled} value={person.startCount} onChange={handleSetCrewPersonField.bind(this, index, 'startCount')} />
                        </div>
                        <div className="field end-count-field">
                            <div className="label">End count <span className="required">*</span>:</div>
                            <input type="number" disabled={fieldsDisabled} value={person.endCount} onChange={handleSetCrewPersonField.bind(this, index, 'endCount')} />
                        </div>
                        <div className="field machine-field">
                            <div className="label">Machine <span className="required">*</span>:</div>
                            <select disabled={fieldsDisabled} value={person.machine} onChange={handleSetCrewPersonField.bind(this, index, 'machine')}>
                                <option value="">Select</option>
                                <option value="Inkjet 1">Inkjet 1</option>
                                <option value="Inkjet 2">Inkjet 2</option>
                                <option value="Inkjet 3">Inkjet 3</option>
                                <option value="Inkjet 4">Inkjet 4</option>
                                <option value="Inkjet 5">Inkjet 5</option>
                                <option value="Inkjet 6">Inkjet 6</option>
                                <option value="Inkjet 7">Inkjet 7</option>
                                <option value="Inkjet 8">Inkjet 8</option>
                                <option value="Inkjet 9">Inkjet 9</option>
                                <option value="Inkjet 10">Inkjet 10</option>
                                <option value="Inserter 1">Inserter 1</option>
                                <option value="Inserter 2">Inserter 2</option>
                                <option value="Inserter 3">Inserter 3</option>
                                <option value="Inserter 4">Inserter 4</option>
                                <option value="Inserter 5">Inserter 5</option>
                                <option value="Inserter 6">Inserter 6</option>
                                <option value="Inserter 7">Inserter 7</option>
                                <option value="Inserter 8">Inserter 8</option>
                                <option value="Inserter 9">Inserter 9</option>
                                <option value="Inserter 10">Inserter 10</option>
                                <option value="Quadient">Quadient</option>
                                <option value="Hands 1">Hands 1</option>
                                <option value="Hands 2">Hands 2</option>
                                <option value="Hands 3">Hands 3</option>
                                <option value="Hands 4">Hands 4</option>
                                <option value="Hands 5">Hands 5</option>
                                <option value="Hands 6">Hands 6</option>
                                <option value="Hands 7">Hands 7</option>
                                <option value="Hands 8">Hands 8</option>
                                <option value="Hands 9">Hands 9</option>
                                <option value="Hands 10">Hands 10</option>
                                <option value="Hands 11">Hands 11</option>
                            </select>
                        </div>
                        <div className="delete-icon">
                            <i className="fas fa-trash" onClick={props.deleteCrewPerson.bind(this, index)} />
                        </div>
                    </div>
                );
            });
        };

        const handleSetJobDetailLineField = (index, name, event) => {
            let value = event.target.value;
            if (event.target.type === 'checkbox') {
                value = event.target.checked ? 1 : 0;
            }
            props.setJobDetailLineField(index, name, value);
        };

        const renderJobDetails = () => {
            return props.closeOutFields.jobDetailsList.map((item, index) => {
                return (
                    <tr>
                        <td>
                            <input type="number" disabled={fieldsDisabled} value={item.skid} onChange={handleSetJobDetailLineField.bind(this, index, 'skid')} />
                        </td>
                        <td>
                            <input type="number" disabled={fieldsDisabled} value={item.drop} onChange={handleSetJobDetailLineField.bind(this, index, 'drop')} />
                        </td>
                        <td>
                            <input type="text" disabled={fieldsDisabled} value={item.sacks} onChange={handleSetJobDetailLineField.bind(this, index, 'sacks')} />
                        </td>
                        <td>
                            <input type="number" disabled={fieldsDisabled} value={item.oneTrays} onChange={handleSetJobDetailLineField.bind(this, index, 'oneTrays')} />
                        </td>
                        <td>
                            <input type="number" disabled={fieldsDisabled} value={item.twoTrays} onChange={handleSetJobDetailLineField.bind(this, index, 'twoTrays')} />
                        </td>
                        <td>
                            <input type="number" disabled={fieldsDisabled} value={item.twoeTrays} onChange={handleSetJobDetailLineField.bind(this, index, 'twoeTrays')} />
                        </td>
                        <td>
                            <input type="number" disabled={fieldsDisabled} value={item.tubs} onChange={handleSetJobDetailLineField.bind(this, index, 'tubs')} />
                        </td>
                        <td className="actions">
                            <i className="fas fa-trash" onClick={props.deleteJobDetailLine.bind(this, index)} />
                        </td>
                    </tr>
                )
            });
        };

        const renderSavedMailingData = () => {
            if (!props.closeOutFields.job) {
                return null;
            }

            let data = null;
            if (parseInt(props.department.id) === 36) {     // Mail Services(36)
                if (props.closeOutFields.job.mailServiceCloseOutData == null) {
                    return null;
                } else {
                    data = props.closeOutFields.job.mailServiceCloseOutData;
                }
            } else if (parseInt(props.department.id) === 12) {   // Inserting(12)
                if (props.closeOutFields.job.insertingCloseOutData == null) {
                    return null;
                } else {
                    data = props.closeOutFields.job.insertingCloseOutData;
                }
            } else if (parseInt(props.department.id) === 26) {    // Hand(26)
                if (props.closeOutFields.job.handCloseOutData == null) {
                    return null;
                } else {
                    data = props.closeOutFields.job.handCloseOutData;
                }
            }

            if (data == null) {
                return null;
            }

            return (
                <Fragment>
                    <div className="mailing-title">
                        Saved Mailing Data:
                    </div>
                    {renderMailingRecord(data)}
                </Fragment>
            );
        }

        return (
            <div className="close-out-container mailing-close-out-container">
                <div className="title">Closeout: {props.department.name}</div>
                <div className="fields">
                    <div className="top-fields">
                        <div>
                            <b>Stagecoach #:</b>
                            <div>{props.closeOutFields.id ? props.closeOutFields.id : null}</div>
                        </div>
                        <div>
                            <b>Customer:</b>
                            <div>{props.closeOutFields.job ? props.closeOutFields.job.customer_name : null}</div>
                        </div>
                        <div>
                            <b>Department:</b>
                            <div>{props.closeOutFields.department ? props.closeOutFields.department : null}</div>
                        </div>
                        <div>
                            <b>Ticket Date:</b>
                            <div>{props.closeOutFields.job ? moment(props.closeOutFields.job.due_date).format('MMM DD, YYYY: h:mm a') : null}</div>
                        </div>
                        <div>
                            <b>Today's Date:</b>
                            <div>{moment().format('MMM DD, YYYY: h:mm a')}</div>
                        </div>
                        <div>
                            <b>Time in department:</b>
                            <div>{props.closeOutFields.job ? props.closeOutFields.job.dp_time : null}</div>
                        </div>
                    </div>
                    {renderSavedMailingData()}
                    <div className="description-field">
                        <b>Description:</b>
                    </div>
                    <div className="crew-information-container">
                        <h4>
                            <div>Crew Information:</div>
                            {!fieldsDisabled && <button onClick={props.addCrewPerson}><i className="fas fa-plus"></i> Add</button>}
                        </h4>
                        <div className="fields">
                            {renderCrewInformation()}
                        </div>
                    </div>
                    <div className="details-container">
                        <div className="job-details-container">
                            <h4>
                                <div>Job Details:</div>
                                {!fieldsDisabled && <button onClick={props.addJobDetailLine}><i className="fas fa-plus"></i> Add</button>}
                            </h4>
                            <table className="table table-sm">
                                <thead>
                                    <tr>
                                        <th>Skid #</th>
                                        <th>Drop #</th>
                                        <th>Sacks</th>
                                        <th>1" Trays</th>
                                        <th>2" Trays</th>
                                        <th>2E Trays</th>
                                        <th>Tubs</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>{renderJobDetails()}</tbody>
                            </table>
                        </div>
                        <div className="other-info-container">
                            <div className="quality-container">
                                <h4>Quality Control By <span className="required">*</span>:</h4>
                                <div className="fields">
                                    <div className="operators">
                                        <div className="gary">
                                            <input type="checkbox" disabled={fieldsDisabled} checked={props.closeOutFields.qa_gary} onChange={setCloseOutFieldHandler.bind(this, 'qa_gary')} /> Gary Terrel
                                        </div>
                                        <div className="margarita">
                                            <input type="checkbox" disabled={fieldsDisabled} checked={props.closeOutFields.qa_margarita} onChange={setCloseOutFieldHandler.bind(this, 'qa_margarita')} /> Margarita Fernandez
                                        </div>
                                        <div className="other">
                                            Other:
                                            <select disabled={fieldsDisabled} value={props.closeOutFields.qa_other} onChange={setCloseOutFieldHandler.bind(this, 'qa_other')}>
                                                <option value="">Select</option>
                                                {props.operators.map((operator) => {
                                                    return <option value={operator.id}>{operator.name}</option>
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="date">
                                        <div>Date:</div>
                                        <div>{moment().format('MMM DD, YYYY: h:mm a')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="barcode-container">
                                <h4>Barcode Check By:</h4>
                                <div className="fields">
                                    <div>
                                        <div>Operator:</div>
                                        <select disabled={fieldsDisabled} value={props.closeOutFields.barcode_checked_by} onChange={setCloseOutFieldHandler.bind(this, 'barcode_checked_by')}>
                                            <option value="">Select</option>
                                            {props.operators.map((operator) => {
                                                return <option value={operator.id}>{operator.name}</option>
                                            })}
                                        </select>
                                    </div>
                                    <div>
                                        <div>Date:</div>
                                        <div>{moment().format('MMM DD, YYYY: h:mm a')}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="leftover-container">
                                <h4>Leftover Material Remarks:</h4>
                                <textarea disabled={fieldsDisabled} value={props.closeOutFields.leftover_material} onChange={setCloseOutFieldHandler.bind(this, 'leftover_material')} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="textarea-field">
                    <b>Job Notes &amp; Remarks:</b>
                    <textarea
                        cols="30"
                        rows="3"
                        placeholder="Enter Comments"
                        disabled={fieldsDisabled}
                        onChange={setCloseOutFieldHandler.bind(this, 'comments')}
                        value={props.closeOutFields.comments}
                    />
                </div>
                <div className={'update-button' + buttonClassName}>
                    {saveMailingButton}
                    {closeButton}
                </div>
            </div>
        );
    }

    const renderMailCloseOutDataForShippingDepartment = () => {
        if (!props.closeOutFields.job || parseInt(props.department.id) !== 56) {    // Postal Accounting (56)
            return null;
        }

        let mailServiceDataContainer = null;
        const mailServiceCloseOutData = props.closeOutFields.job.mailServiceCloseOutData;
        if (!empty(mailServiceCloseOutData)) {
            mailServiceDataContainer = (
                <Fragment>
                    <div className="mailing-title">
                        From Mail Services Closeout
                    </div>
                    {renderMailingRecord(mailServiceCloseOutData, 'Mail Services')}
                </Fragment>
            );
        }

        let insertingDataContainer = null;
        const insertingCloseOutData = props.closeOutFields.job.insertingCloseOutData;
        if (!empty(insertingCloseOutData)) {
            insertingDataContainer = (
                <Fragment>
                    <div className="mailing-title">
                        From Inserting Closeout
                    </div>
                    {renderMailingRecord(insertingCloseOutData, 'Inserting')}
                </Fragment>
            );
        }

        let handDataContainer = null;
        const handCloseOutData = props.closeOutFields.job.handCloseOutData;
        if (!empty(handCloseOutData)) {
            handDataContainer = (
                <Fragment>
                    <div className="mailing-title">
                        From Hands Closeout
                    </div>
                    {renderMailingRecord(handCloseOutData, 'Hands')}
                </Fragment>
            );
        }

        return (
            <Fragment>
                {mailServiceDataContainer}
                {insertingDataContainer}
                {handDataContainer}
            </Fragment>
        );
    };

    return (
        <div className="close-out-container">
            <div className="title">Closeout: {props.department.name}</div>
            <div className="fields">
                <div className="top-fields">
                    <div>
                        <b>Stagecoach Number:</b>
                        <div>{props.closeOutFields.id ? props.closeOutFields.id : null}</div>
                    </div>
                    <div>
                        <b>Department:</b>
                        <div>{props.closeOutFields.department ? props.closeOutFields.department : null}</div>
                    </div>
                    <div>
                        <b>Status:</b>
                        <div>{props.closeOutFields.status ? props.closeOutFields.status : null}</div>
                    </div>
                    <div>
                        <b>Operator:</b>
                        <div>{props.closeOutFields.operator ? props.closeOutFields.operator : null}</div>
                    </div>
                    <div>
                        <b>Today Date:</b>
                        <div>{moment().format('MMMM Do YYYY, h:mm a')}</div>
                    </div>
                </div>
            </div>
            <div className="partials-list">{renderPartials()}</div>
            <div className="shippings-list">{renderShippings()}</div>
            <div className="subdepartments-list">{renderSubdepartments()}</div>
            <div className="mail-closeout-data">{renderMailCloseOutDataForShippingDepartment()}</div>
            <div className="textarea-field">
                <b>Comments:</b>
                <textarea
                    cols="30"
                    rows="3"
                    placeholder="Enter Comments"
                    disabled={fieldsDisabled}
                    onChange={setCloseOutFieldHandler.bind(this, 'comments')}
                    value={props.closeOutFields.comments}
                />
            </div>
            <div className={'update-button' + buttonClassName}>
                {savePartialButton}
                {saveShippingButton}
                {closeButton}
            </div>
        </div>
    );
});

export default CloseOut;
