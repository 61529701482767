import axios from 'axios';
import * as types from './PqtDepartment.types';
import * as selectors from './PqtDepartment.selectors';
import * as loginSelectors from './../Login/Login.selectors';
import {
    showDataSavedNotification,
    showDataDeletedNotification,
    validateAuthInResponse,
    validateMultiAuthInResponse,
    showCompleteDataNotification,
    showNotification,
    sendWsMessage,
} from './../../utils';

const apiUrl = process.env.REACT_APP_API_URL;

export const fetchData = (options = {}, isWsUpdate = false) => (dispatch, getState) => {
    let departmentId = selectors.getId(getState());
    const searchFields = selectors.getSearchFields(getState());
    const sort = selectors.getSort(getState());
    const pagination = selectors.getPagination(getState());
    const showJobs = selectors.getShowJobs(getState());

    if (options.departmentId) {
        departmentId = options.departmentId;
    }

    let params = ['showJobs=' + showJobs];
    if (options.fetchJobs) {
        dispatch({ type: types.FETCH_JOBS, payload: isWsUpdate });
        params.push('fetch-jobs=1');
        params.push('id=' + departmentId);
        if (searchFields.jobNumber !== '') {
            params.push('jobNumber=' + searchFields.jobNumber);
        }
        if (searchFields.date !== '') {
            params.push('date=' + searchFields.date);
        }
        if (searchFields.csr !== '') {
            params.push('csr=' + searchFields.csr);
        }
        if (searchFields.salesRep !== '') {
            params.push('salesRep=' + searchFields.salesRep);
        }
        params.push('sort=' + (sort.type === 'asc' ? '' : '-') + sort.column);
        params.push('active-page=' + pagination.jobs.activePage);
        params.push('elements-per-page=' + pagination.jobs.elementsPerPage);
    }
    if (options.fetchDepartment) {
        dispatch({ type: types.FETCH_DEPARTMENT, payload: null });
        params.push('fetch-department=1');
        params.push('department-id=' + departmentId);
    }

    dispatch({ type: types.FETCH_DATA, payload: null });
    axios.get(apiUrl + '/pqt-tracking/department-data' + (params.length === 0 ? '' : '?' + params.join('&')),
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } })
        .then((response) => {
            dispatch({ type: types.FETCH_DATA_SUCCESS, payload: null });
            if (options.fetchJobs) {
                dispatch({ type: types.FETCH_JOBS_SUCCESS, payload: response.data.jobs });
            }
            if (options.fetchDepartment) {
                dispatch({ type: types.FETCH_DEPARTMENT_SUCCESS, payload: response.data.department });
            }
            if (options.fetchDepartment) {
                dispatch({ type: types.FETCH_OPERATORS_SUCCESS, payload: response.data.operators });
            }
        })
        .catch((error) => {
            let errorTypes = [types.FETCH_DATA_ERROR];
            if (options.fetchJobs) {
                errorTypes.push(types.FETCH_JOBS_ERROR);
            }
            if (options.fetchDepartment) {
                errorTypes.push(types.FETCH_DEPARTMENT_ERROR);
            }
            dispatch(validateMultiAuthInResponse(error, errorTypes));
        });
}

export const resetState = () => (dispatch) => {
    dispatch({
        type: types.RESET_STATE,
        payload: null
    });
}

export const deleteTracking = (id) => (dispatch) => {
    dispatch({ type: types.DELETE_TRACKING, payload: null });
    axios.delete(apiUrl + '/pqt-trackings/' + id,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then(() => {
        dispatch({ type: types.DELETE_TRACKING_SUCCESS, payload: null });
        showDataDeletedNotification();
        dispatch(fetchData({
            fetchJobs: 1,
        }));
        dispatch(sendWsMessage({ id, type: 'track_deleted' }));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.DELETE_TRACKING_ERROR));
    });
}

export const setId = (id, showJobs) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ID,
            payload: { id: parseInt(id), showJobs: parseInt(showJobs) }
        });
        resolve();
    }).then(() => {
        dispatch(fetchData({
            fetchJobs: 1,
        }));
    });
}

export const setSearchField = (name, value) => (dispatch) => {
    dispatch({
        type: types.SET_SEARCH_FIELD,
        payload: { name, value }
    });
}

export const setCloseOutField = (name, value) => (dispatch) => {
    dispatch({
        type: types.SET_CLOSE_OUT_FIELD,
        payload: { name, value }
    });
}

export const isEmptyField = (value) => {
    if (!value || value === undefined || value === '' || value == null) {
        return true;
    }

    return false;
}

export const closeOutJob = () => (dispatch, getState) => {
    const closeOutFields = selectors.getCloseOutFields(getState());
    const department = selectors.getDepartment(getState());
    const maildatFile = selectors.getMaildatFile(getState());
    const departmentId = parseInt(department.id);

    if ([36, 12, 26].includes(parseInt(department.id))) {  // Mail Services, Inserting, Hand
        if (closeOutFields.crewInformation.length === 0) {
            return showNotification('Complete Information', 'Enter at least one Crew Member information.', 'info');
        }

        for (let i = 0; i < closeOutFields.crewInformation.length; i++) {
            const element = closeOutFields.crewInformation[i];
            if (
                isEmptyField(element.operator) || isEmptyField(element.roll) || isEmptyField(element.dateIn) ||
                isEmptyField(element.dateOut) || isEmptyField(element.timeIn) || isEmptyField(element.timeOut) ||
                isEmptyField(element.startCount) || isEmptyField(element.endCount) || isEmptyField(element.machine)
            ) {
                return showCompleteDataNotification();
            }
        }

        if (isEmptyField(closeOutFields.qa_gary) && isEmptyField(closeOutFields.qa_margarita) && isEmptyField(closeOutFields.qa_other)) {
            return showNotification('No Quality Control Selected', 'Select at least one person for the Quality Control.', 'info');
        }
    } else {
        if (department.subdepartments.length > 0) {
            if (closeOutFields.tasks.length === 0) {
                showNotification('No Tasks Selected', 'There are no tasks selected. Please select at least one to Closeout.', 'info');
                return;
            }

            for (let i = 0; i < closeOutFields.tasks.length; i++) {
                let task = closeOutFields.tasks[i];
                if (task.equipment === '' || task.final_quantity === '') {
                    showCompleteDataNotification();
                    return;
                }
            }
        } else {
            if (departmentId !== 56 && departmentId !== 17 && (closeOutFields.tasks[0].equipment === undefined || closeOutFields.tasks[0].equipment === '')) {
                showCompleteDataNotification();
                return;
            }
            if (departmentId !== 56 && departmentId !== 17 && departmentId !== 23 && isEmptyField(closeOutFields.tasks[0].final_quantity)) {
                showCompleteDataNotification();
                return;
            }

            if (departmentId === 17) {     // Shipping
                for (let i = 0; i < closeOutFields.tasks[0].shippings.length; i++) {
                    const shipping = closeOutFields.tasks[0].shippings[i];
                    if (shipping.quantity === undefined || shipping.quantity === null || shipping.quantity === '') {
                        return showCompleteDataNotification();
                    }
                    if (shipping.shipping_method === undefined || shipping.shipping_method === null || shipping.shipping_method === '') {
                        return showCompleteDataNotification();
                    }
                    if (shipping.tracking_number === undefined || shipping.tracking_number === null || shipping.tracking_number === '') {
                        return showCompleteDataNotification();
                    }
                    if (shipping.shipping_cost === undefined || shipping.shipping_cost === null || shipping.shipping_cost === '') {
                        return showCompleteDataNotification();
                    }
                }
            }
            if (departmentId === 55) {     // Postal Qualification
                if (closeOutFields.tasks[0].postage === undefined || closeOutFields.tasks[0].postage === null || closeOutFields.tasks[0].postage === '') {
                    showCompleteDataNotification();
                    return;
                }
            }
            if (departmentId === 56) {     // Postal Accounting
                for (let i = 0; i < closeOutFields.tasks[0].partials.length; i++) {
                    const partial = closeOutFields.tasks[0].partials[i];
                    if (partial.project_mail === undefined || partial.project_mail === null || partial.project_mail === '') {
                        return showCompleteDataNotification();
                    }
                    if (partial.mail_type === undefined || partial.mail_type === null || partial.mail_type === '') {
                        return showCompleteDataNotification();
                    }
                    if (partial.post_office === undefined || partial.post_office === null || partial.post_office === '') {
                        return showCompleteDataNotification();
                    }
                    if (partial.final_quantity === undefined || partial.final_quantity === null || partial.final_quantity === '') {
                        return showCompleteDataNotification();
                    }
                    if (partial.price === undefined || partial.price === null || partial.price === '') {
                        return showCompleteDataNotification();
                    }

                    let projectMail = parseInt(partial.project_mail);
                    if (projectMail !== 3 && projectMail !== 4 && isEmptyField(partial.permit_number)) {
                        showCompleteDataNotification();
                        return;
                    }
                }
            }
            if (departmentId === 23) {     // Art & Graphic
                if (closeOutFields.tasks[0].art_days === undefined || closeOutFields.tasks[0].art_days === null || closeOutFields.tasks[0].art_days === '') {
                    showCompleteDataNotification();
                    return;
                }
                if (closeOutFields.tasks[0].art_hours === undefined || closeOutFields.tasks[0].art_hours === null || closeOutFields.tasks[0].art_hours === '') {
                    showCompleteDataNotification();
                    return;
                }
                if (closeOutFields.tasks[0].art_minutes === undefined || closeOutFields.tasks[0].art_minutes === null || closeOutFields.tasks[0].art_minutes === '') {
                    showCompleteDataNotification();
                    return;
                }
                if (closeOutFields.tasks[0].art_proofs === undefined || closeOutFields.tasks[0].art_proofs === null || closeOutFields.tasks[0].art_proofs === '') {
                    showCompleteDataNotification();
                    return;
                }
                if (closeOutFields.tasks[0].art_proof_type === undefined || closeOutFields.tasks[0].art_proof_type === null || closeOutFields.tasks[0].art_proof_type === '') {
                    showCompleteDataNotification();
                    return;
                }
            }
        }
    }

    let data = closeOutFields;
    if (departmentId === 55) {     // Postal Qualification
        let formData = new FormData();
        formData.append('id', closeOutFields.id);
        formData.append('department_id', closeOutFields.department_id);
        formData.append('status', closeOutFields.status);
        formData.append('operator', closeOutFields.operator);
        formData.append('employee_id', closeOutFields.employee_id);
        formData.append('comments', isEmptyField(closeOutFields.comments) ? '' : closeOutFields.comments);
        formData.append('tasks', JSON.stringify([{
            id: closeOutFields.tasks[0].id,
            equipment: closeOutFields.tasks[0].equipment,
            final_quantity: closeOutFields.tasks[0].final_quantity,
            postage: closeOutFields.tasks[0].postage,
        }]));

        if (maildatFile != null) {
            formData.append('maildatFile', maildatFile);
        }

        data = formData;
    } else if (departmentId === 56) {     // Postal Accounting
        let formData = new FormData();
        formData.append('id', closeOutFields.id);
        formData.append('department_id', closeOutFields.department_id);
        formData.append('status', closeOutFields.status);
        formData.append('operator', closeOutFields.operator);
        formData.append('employee_id', closeOutFields.employee_id);
        formData.append('comments', isEmptyField(closeOutFields.comments) ? '' : closeOutFields.comments);
        formData.append('tasks', JSON.stringify([{
            id: 0,
            partials: closeOutFields.tasks[0].partials,
        }]));

        if (maildatFile != null) {
            formData.append('maildatFile', maildatFile);
        }

        data = formData;
    }

    dispatch({ type: types.CLOSE_OUT_JOB, payload: null });
    axios.post(apiUrl + '/pqt-tracking-step/close-out-department',
        data,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.CLOSE_OUT_JOB_SUCCESS, payload: null });
        showDataSavedNotification();
        dispatch(fetchData({
            fetchJobs: 1,
        }));
        dispatch({
            type: types.SELECT_JOB,
            payload: {
                closing: false,
                savingPartial: false,
                savingShipping: false,
                tasks: [],
                crewInformation: [{
                    operator: '',
                    roll: '',
                    dateIn: null,
                    dateOut: null,
                    timeIn: null,
                    timeOut: null,
                    startCount: '',
                    endCount: '',
                    machine: '',
                }],
                jobDetailsList: [{
                    skid: '',
                    drop: '',
                    sacks: '',
                    oneTrays: '',
                    twoTrays: '',
                    twoeTrays: '',
                    tubs: '',
                }],
                qa_gary: 0,
                qa_margarita: 0,
                qa_other: '',
                barcode_checked_by: '',
                leftover_material: '',
            }
        });
        dispatch(sendWsMessage({ id: closeOutFields.id, type: 'track_closed' }));
        dispatch(sendWsMessage({ type: 'invoice_closed' }));
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.CLOSE_OUT_JOB_ERROR));
    });
}

export const savePartial = () => (dispatch, getState) => {
    const closeOutFields = selectors.getCloseOutFields(getState());

    for (let i = 0; i < closeOutFields.tasks[0].partials.length; i++) {
        const partial = closeOutFields.tasks[0].partials[i];
        if (partial.project_mail === undefined || partial.project_mail === null || partial.project_mail === '') {
            return showCompleteDataNotification();
        }
        if (partial.mail_type === undefined || partial.mail_type === null || partial.mail_type === '') {
            return showCompleteDataNotification();
        }
        if (partial.post_office === undefined || partial.post_office === null || partial.post_office === '') {
            return showCompleteDataNotification();
        }
        if (partial.final_quantity === undefined || partial.final_quantity === null || partial.final_quantity === '') {
            return showCompleteDataNotification();
        }
        if (partial.price === undefined || partial.price === null || partial.price === '') {
            return showCompleteDataNotification();
        }

        let projectMail = parseInt(partial.project_mail);
        if (projectMail !== 3 && projectMail !== 4 && isEmptyField(partial.permit_number)) {
            showCompleteDataNotification();
            return;
        }
    }

    dispatch({ type: types.SAVE_PARTIAL, payload: null });
    axios.post(apiUrl + '/pqt-tracking-step/save-partial',
        closeOutFields,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.SAVE_PARTIAL_SUCCESS, payload: null });
        showDataSavedNotification();
        dispatch(fetchData({
            fetchJobs: 1,
        }));
        dispatch({
            type: types.SELECT_JOB,
            payload: { closing: false, tasks: [], crewInformation: [], jobDetailsList: [], }
        });
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SAVE_PARTIAL_ERROR));
    });
}

export const saveShipping = () => (dispatch, getState) => {
    const closeOutFields = selectors.getCloseOutFields(getState());

    for (let i = 0; i < closeOutFields.tasks[0].shippings.length; i++) {
        const shipping = closeOutFields.tasks[0].shippings[i];
        if (shipping.quantity === undefined || shipping.quantity === null || shipping.quantity === '') {
            return showCompleteDataNotification();
        }
        if (shipping.shipping_method === undefined || shipping.shipping_method === null || shipping.shipping_method === '') {
            return showCompleteDataNotification();
        }
        if (shipping.tracking_number === undefined || shipping.tracking_number === null || shipping.tracking_number === '') {
            return showCompleteDataNotification();
        }
        if (shipping.shipping_cost === undefined || shipping.shipping_cost === null || shipping.shipping_cost === '') {
            return showCompleteDataNotification();
        }
    }

    dispatch({ type: types.SAVE_SHIPPING, payload: null });
    axios.post(apiUrl + '/pqt-tracking-step/save-shipping',
        closeOutFields,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.SAVE_SHIPPING_SUCCESS, payload: null });
        showDataSavedNotification();
        dispatch(fetchData({
            fetchJobs: 1,
        }));
        dispatch({
            type: types.SELECT_JOB,
            payload: { closing: false, tasks: [], crewInformation: [], jobDetailsList: [], }
        });
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SAVE_SHIPPING_ERROR));
    });
}

export const saveMailing = () => (dispatch, getState) => {
    const closeOutFields = selectors.getCloseOutFields(getState());
    const department = selectors.getDepartment(getState());
    const departmentId = parseInt(department.id);

    if ([36, 12, 26].includes(departmentId)) {  // Mail Services, Inserting, Hand
        if (closeOutFields.crewInformation.length === 0) {
            return showNotification('Complete Information', 'Enter at least one Crew Member information.', 'info');
        }

        for (let i = 0; i < closeOutFields.crewInformation.length; i++) {
            const element = closeOutFields.crewInformation[i];
            if (
                isEmptyField(element.operator) || isEmptyField(element.roll) || isEmptyField(element.dateIn) ||
                isEmptyField(element.dateOut) || isEmptyField(element.timeIn) || isEmptyField(element.timeOut) ||
                isEmptyField(element.startCount) || isEmptyField(element.endCount) || isEmptyField(element.machine)
            ) {
                return showCompleteDataNotification();
            }
        }

        if (isEmptyField(closeOutFields.qa_gary) && isEmptyField(closeOutFields.qa_margarita) && isEmptyField(closeOutFields.qa_other)) {
            return showNotification('No Quality Control Selected', 'Select at least one person for the Quality Control.', 'info');
        }
    }

    dispatch({ type: types.SAVE_MAILING, payload: null });
    axios.post(apiUrl + '/pqt-tracking-step/save-mailing',
        closeOutFields,
        { headers: { 'Authorization': `Bearer ${localStorage.getItem('access_token')}` } }
    ).then((response) => {
        dispatch({ type: types.SAVE_MAILING_SUCCESS, payload: null });
        showDataSavedNotification();
        dispatch(fetchData({
            fetchJobs: 1,
        }));
        dispatch({
            type: types.SELECT_JOB,
            payload: { closing: false, tasks: [], crewInformation: [], jobDetailsList: [], }
        });
    }).catch((error) => {
        dispatch(validateAuthInResponse(error, types.SAVE_MAILING_ERROR));
    });
}

export const selectJob = (id) => (dispatch, getState) => {
    const user = loginSelectors.getUser(getState());
    const department = selectors.getDepartment(getState());
    const data = selectors.getData(getState());

    let job = null;
    for (let i = 0; i < data.jobs.length; i++) {
        if (parseInt(data.jobs[i].id) === parseInt(id)) {
            job = data.jobs[i];
            break;
        }
    }

    let currentDepartments = [];
    for (let i = 0; i < job.current_departments.length; i++) {
        currentDepartments.push(job.current_departments[i]);
    }

    if (job === null) {
        showNotification('Not Found', 'The job was not found.', 'info');
        return;
    } else if (!currentDepartments.includes(parseInt(department.id))) {
        showNotification('Not Ready', 'The job is not ready to close.', 'info');
        return;
    }

    dispatch({
        type: types.SELECT_JOB,
        payload: {
            id: job.id,
            department_id: department.id,
            subdepartment_id: '',
            job_number: job.job_number,
            department: department.name,
            status: 'Finished',
            operator: user.name,
            employee_id: user.id,
            job,
            closing: false,
            tasks: [],
            crewInformation: [{
                operator: '',
                roll: '',
                dateIn: null,
                dateOut: null,
                timeIn: null,
                timeOut: null,
                startCount: '',
                endCount: '',
                machine: '',
            }],
            jobDetailsList: [{
                skid: '',
                drop: '',
                sacks: '',
                oneTrays: '',
                twoTrays: '',
                twoeTrays: '',
                tubs: '',
            }],
            qa_gary: 0,
            qa_margarita: 0,
            qa_other: '',
            barcode_checked_by: '',
            leftover_material: '',
        }
    });

    if (department.subdepartments.length === 0) {
        dispatch(addTask(0, department.id, job));
    }
}

export const setBarcode = (value) => (dispatch) => {
    dispatch({
        type: types.SET_BARCODE,
        payload: value
    });
}

export const setBarcodeCapturing = (value) => (dispatch) => {
    dispatch({
        type: types.SET_BARCODE_CAPTURING,
        payload: value
    });
}

export const setSort = (column, type) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SORT,
            payload: {
                column: column,
                type: type,
            }
        });
        resolve();
    }).then(() => {
        dispatch(fetchData({
            fetchJobs: 1,
        }));
    });
}

export const setActivePage = (name, value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_ACTIVE_PAGE,
            payload: { name, value }
        });
        resolve();
    }).then(() => {
        if (name === 'jobs') {
            dispatch(fetchData({
                fetchJobs: 1,
            }));
        }
    });
}

export const addTask = (id, departmentId, job) => (dispatch) => {
    dispatch({
        type: types.ADD_TASK,
        payload: { id: parseInt(id), departmentId: parseInt(departmentId), job }
    });
}

export const removeTask = (id) => (dispatch) => {
    dispatch({
        type: types.REMOVE_TASK,
        payload: id
    });
}

export const setTaskField = (id, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_TASK_FIELD,
        payload: { id, field, value }
    });
}

export const setShowJobs = (value) => (dispatch) => {
    new Promise(resolve => {
        dispatch({
            type: types.SET_SHOW_JOBS,
            payload: value
        });
        resolve();
    }).then(() => {
        dispatch(fetchData({ fetchJobs: 1 }));
    });
}

export const mountComponent = (history, id, showJobs) => (dispatch, getState) => {
    const user = loginSelectors.getUser(getState());

    dispatch(resetState());
    const pqtType = user !== null && user.pqt_type;
    if ([6, 7].includes(pqtType) && !user.pqt_departments.includes(parseInt(id))) {
        history.push('/department/' + user.pqt_departments[0]);
        dispatch(setId(user.pqt_departments[0], showJobs));
        dispatch(fetchData({
            fetchDepartment: 1,
            departmentId: user.pqt_department,
        }));
    } else {
        dispatch(setId(id, showJobs));
        dispatch(fetchData({
            fetchDepartment: 1,
            departmentId: id,
        }));
    }
}

export const setPartialField = (index, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_PARTIAL_FIELD,
        payload: { index, field, value }
    });
}

export const addPartial = () => (dispatch) => {
    dispatch({
        type: types.ADD_PARTIAL,
        payload: null
    });
}

export const removePartial = () => (dispatch) => {
    dispatch({
        type: types.REMOVE_PARTIAL,
        payload: null
    });
}

export const setShippingField = (index, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_SHIPPING_FIELD,
        payload: { index, field, value }
    });
}

export const addShipping = () => (dispatch) => {
    dispatch({
        type: types.ADD_SHIPPING,
        payload: null
    });
}

export const removeShipping = () => (dispatch) => {
    dispatch({
        type: types.REMOVE_SHIPPING,
        payload: null
    });
}

export const addCrewPerson = () => (dispatch) => {
    dispatch({
        type: types.ADD_CREW_PERSON,
        payload: null,
    });
}

export const deleteCrewPerson = (index) => (dispatch) => {
    dispatch({
        type: types.DELETE_CREW_PERSON,
        payload: index,
    });
}

export const setCrewPersonField = (index, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_CREW_PERSON_FIELD,
        payload: { index, field, value }
    });
}

export const addJobDetailLine = () => (dispatch) => {
    dispatch({
        type: types.ADD_JOB_DETAIL_LINE,
        payload: null,
    });
}

export const deleteJobDetailLine = (index) => (dispatch) => {
    dispatch({
        type: types.DELETE_JOB_DETAIL_LINE,
        payload: index,
    });
}

export const setJobDetailLineField = (index, field, value) => (dispatch) => {
    dispatch({
        type: types.SET_JOB_DETAIL_LINE_FIELD,
        payload: { index, field, value }
    });
}

export const addMaildatFile = (file) => (dispatch) => {
    dispatch({
        type: types.ADD_MAILDAT_FILE,
        payload: file,
    });
}