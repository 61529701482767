import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Items from './Items';
import DeleteModal from './DeleteModal';
import FormModal from './FormModal';
import UploadData from './UploadData';
import PushModal from './PushModal';
import * as actions from './ApItems.actions';
import * as selectors from './ApItems.selectors';
import './ApItems.scss';
import PqtLayout from './../PqtLayout';
import { Helmet } from 'react-helmet';
import { stringifyEqualityCheck } from './../../utils';
import * as dialog from '../common/dialog';
import * as loginSelectors from './../Login/Login.selectors';
import { Redirect } from 'react-router-dom';

const ApItems = (props) => {
    /* State to props */
    const fetchingItems = useSelector(selectors.getFetchingItems);
    const items = useSelector(selectors.getItems, stringifyEqualityCheck);
    const pagination = useSelector(selectors.getPagination, stringifyEqualityCheck);
    const sort = useSelector(selectors.getSort, stringifyEqualityCheck);
    const idForDelete = useSelector(selectors.getIdForDelete, stringifyEqualityCheck);
    const isDeleting = useSelector(selectors.getIsDeleting, stringifyEqualityCheck);
    const filerFields = useSelector(selectors.getFilterFields, stringifyEqualityCheck);
    const uploadingFile = useSelector(selectors.getUploadingFile, stringifyEqualityCheck);
    const fields = useSelector(selectors.getFields, stringifyEqualityCheck);
    const fetchingItem = useSelector(selectors.getFetchingItem, stringifyEqualityCheck);
    const savingItem = useSelector(selectors.getSavingItem);
    const pushingToQb = useSelector(selectors.getPushingToQb);
    const file = useSelector(selectors.getFile, stringifyEqualityCheck);
    const type = useSelector(selectors.getType);
    const processedData = useSelector(selectors.getProcessedData, stringifyEqualityCheck);
    const information = useSelector(selectors.getInformation, stringifyEqualityCheck);
    const fetchingInformation = useSelector(selectors.getFetchingInformation);
    const savingItems = useSelector(selectors.getSavingItems);
    const name = useSelector(selectors.getName);
    const postedDate = useSelector(selectors.getPostedDate, stringifyEqualityCheck);
    const record = useSelector(selectors.getRecord, stringifyEqualityCheck);
    const fetchingRecord = useSelector(selectors.getFetchingRecord);
    const user = useSelector(loginSelectors.getUser, stringifyEqualityCheck);

    /* Dispatch to props */
    const dispatch = useDispatch();
    const deleteItem = useCallback((id, comments) => dispatch(actions.deleteItem(id, comments)), [dispatch]);
    const setSort = useCallback((column, type) => dispatch(actions.setSort(column, type)), [dispatch]);
    const setActivePage = useCallback((name, value) => dispatch(actions.setActivePage(name, value)), [dispatch]);
    const fetchItems = useCallback((options, isWsUpdate) => dispatch(actions.fetchItems(options, isWsUpdate)), [dispatch]);
    const setIdForDelete = useCallback((id) => dispatch(actions.setIdForDelete(id)), [dispatch]);
    const changeFilter = useCallback((name, value, fetch) => dispatch(actions.changeFilter(name, value, fetch)), [dispatch]);
    const uploadFile = useCallback(() => dispatch(actions.uploadFile()), [dispatch]);
    const setField = useCallback((field, value) => dispatch(actions.setField(field, value)), [dispatch]);
    const resetState = useCallback(() => dispatch(actions.resetState()), [dispatch]);
    const fetchItem = useCallback((id) => dispatch(actions.fetchItem(id)), [dispatch]);
    const clearForm = useCallback(() => dispatch(actions.clearForm()), [dispatch]);
    const saveItem = useCallback(() => dispatch(actions.saveItem()), [dispatch]);
    const pushToQb = useCallback(() => dispatch(actions.pushToQb()), [dispatch]);
    const setFile = useCallback((file) => dispatch(actions.setFile(file)), [dispatch]);
    const setType = useCallback((type) => dispatch(actions.setType(type)), [dispatch]);
    const fetchInformation = useCallback(() => dispatch(actions.fetchInformation()), [dispatch]);
    const setProcessedDataField = useCallback((index, field, value) => dispatch(actions.setProcessedDataField(index, field, value)), [dispatch]);
    const saveItems = useCallback(() => dispatch(actions.saveItems()), [dispatch]);
    const setName = useCallback((value) => dispatch(actions.setName(value)), [dispatch]);
    const setPostedDate = useCallback((value) => dispatch(actions.setPostedDate(value)), [dispatch]);
    const setRecordId = useCallback((value) => dispatch(actions.setRecordId(value)), [dispatch]);
    const updateField = useCallback((id, field, value) => dispatch(actions.updateField(id, field, value)), [dispatch]);
    const updateFieldLocal = useCallback((id, field, value) => dispatch(actions.updateFieldLocal(id, field, value)), [dispatch]);
    const setHistory = useCallback((value) => dispatch(actions.setHistory(value)), [dispatch]);
    const fetchRecord = useCallback(() => dispatch(actions.fetchRecord()), [dispatch]);
    const showDeleteModal = useCallback(() => dispatch(dialog.actions.showDialog('delete-modal')), [dispatch]);
    const hideDeleteModal = useCallback(() => dispatch(dialog.actions.hideDialog('delete-modal')), [dispatch]);
    const showFormModal = useCallback(() => dispatch(dialog.actions.showDialog('ap-form-modal')), [dispatch]);
    const hideFormModal = useCallback(() => dispatch(dialog.actions.hideDialog('ap-form-modal')), [dispatch]);
    const showPushModal = useCallback(() => dispatch(dialog.actions.showDialog('push-modal')), [dispatch]);
    const hidePushModal = useCallback(() => dispatch(dialog.actions.hideDialog('push-modal')), [dispatch]);

    /* componentDidMount */
    useEffect(() => {
        setHistory(props.history)
        fetchInformation();

        if (props.match.params.type === 'upload-american-express') {
            setType(1);
        } else if (props.match.params.type === 'upload-capital-one-promovana') {
            setType(2);
        } else if (props.match.params.type === 'upload-capital-one-paper') {
            setType(3);
        } else if (props.match.params.type === 'american-express') {
            setType(4);
            setSort('card_member', 'asc');
            setRecordId(props.match.params.id);
            fetchRecord();
            fetchItems();
        } else if (props.match.params.type === 'capital-one-promovana') {
            setType(5);
            setSort('card_number', 'asc');
            setRecordId(props.match.params.id);
            fetchRecord();
            fetchItems();
        } else if (props.match.params.type === 'capital-one-paper') {
            setType(6);
            setSort('card_number', 'asc');
            setRecordId(props.match.params.id);
            fetchRecord();
            fetchItems();
        }

        return () => {
            resetState();
        };
    }, [fetchItems, resetState, setType, fetchInformation, setRecordId, setHistory, setSort, fetchRecord,
        props.match.params.type, props.match.params.id, props.history]);

    if (user != null && ![61, 1147].includes(parseInt(user.id))) {     // Hugh (61), AP (1147)
        return <Redirect to="/dashboard" />;
    }

    let content = null;
    if (parseInt(type) === 1 || parseInt(type) === 2 || parseInt(type) === 3) {
        content = (
            <div className="ap-items-container">
                <UploadData
                    type={type}
                    file={file}
                    uploadingFile={uploadingFile}
                    processedData={processedData}
                    information={information}
                    fetchingInformation={fetchingInformation}
                    savingItems={savingItems}
                    name={name}
                    postedDate={postedDate}
                    setFile={setFile}
                    uploadFile={uploadFile}
                    setProcessedDataField={setProcessedDataField}
                    saveItems={saveItems}
                    setName={setName}
                    setPostedDate={setPostedDate}
                />
            </div>
        );
    } else if (parseInt(type) === 4 || parseInt(type) === 5 || parseInt(type) === 6) {
        content = (
            <div className="ap-items-container">
                <Items
                    type={type}
                    items={items}
                    pagination={pagination}
                    fetchingItems={fetchingItems}
                    history={props.history}
                    sort={sort}
                    user={user}
                    filerFields={filerFields}
                    information={information}
                    pushingToQb={pushingToQb}
                    record={record}
                    fetchingRecord={fetchingRecord}
                    setSort={setSort}
                    setActivePage={setActivePage}
                    showDeleteModal={showDeleteModal}
                    setIdForDelete={setIdForDelete}
                    changeFilter={changeFilter}
                    fetchItems={fetchItems}
                    showFormModal={showFormModal}
                    fetchItem={fetchItem}
                    updateField={updateField}
                    updateFieldLocal={updateFieldLocal}
                    pushToQb={pushToQb}
                    showPushModal={showPushModal}
                />
                <DeleteModal
                    type={type}
                    idForDelete={idForDelete}
                    isDeleting={isDeleting}
                    deleteItem={deleteItem}
                    hideDeleteModal={hideDeleteModal}
                    setIdForDelete={setIdForDelete}
                />
                <FormModal
                    type={type}
                    savingItem={savingItem}
                    fields={fields}
                    fetchingItem={fetchingItem}
                    information={information}
                    saveItem={saveItem}
                    hideFormModal={hideFormModal}
                    setField={setField}
                    clearForm={clearForm}
                />
                <PushModal
                    pushingToQb={pushingToQb}
                    pushToQb={pushToQb}
                    hidePushModal={hidePushModal}
                />
            </div>
        );
    }

    return (
        <PqtLayout title="AP Items" history={props.history}>
            <Helmet><title>AP Items - Stagecoach</title></Helmet>
            {content}
        </PqtLayout>
    );
};

export default ApItems;
